import React, { useState, useEffect } from 'react';
import {
    SearchOutlined,
    DownloadOutlined
} from '@ant-design/icons';
import {
    Menu,
    Input,
    Button,
    Skeleton,
    Pagination,
    Image
} from 'antd';
import './index.css';
import download from '../../../images/download.png';
import check from '../../../images/check.png';
import none_data_one from '../../../images/none_data_one.png';
import img_load_error from '../../../images/img_load_error.png';
import loading_img1 from '../../../images/loading_img1.png';
import loading_img2 from '../../../images/loading_img2.png';
import { GetImagesHistory, DeleteSelectedTask, StylerSyncRegenAPI } from '../../../api/request.js';
import { useTasks, useTasksDispatch } from '../../../TasksContext/index.js';
import { useAuth } from 'react-oidc-context';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

const items = [
    // {
    //     label: 'All Models',
    //     key: 'all',
    // },
    // {
    //     label: 'Text To Image',
    //     key: 'text',
    // },
    // {
    //     label: 'Image To Image',
    //     key: 'image',
    // }
];

const MyArtSpace = () => {
    const tasks = useTasks();
    const dispatch = useTasksDispatch();
    const auth = useAuth();
    const [current, setCurrent] = useState('all');
    const [isEdit, setIsEdit] = useState(false);
    const [renderListData, setRenderListData] = useState([]);

    const onClick = (e) => {
        console.log('click ', e);
        setCurrent(e.key);
    };

    const editClick = () => {
        setIsEdit(true);
    };

    const cancelOnClick = (index) => {
        setRenderListData((oldRenderListData) => {
            const newRenderListData = [...oldRenderListData];
            newRenderListData[index].isDelete = false;
            return newRenderListData;
        });
    };
    const cancelDeleteOnClick = (index) => {
        setRenderListData((oldRenderListData) => {
            const newRenderListData = [...oldRenderListData];
            newRenderListData[index].isDelete = true;
            return newRenderListData;
        });
    };
    const allDelete = () => {
        setRenderListData((oldRenderListData) => {
            const newRenderListData = [...oldRenderListData];
            for (let i = 0; i < newRenderListData.length; i++) {
                newRenderListData[i].isDelete = true;
            }
            return newRenderListData;
        });
    };
    const cancelDelete = () => {
        setRenderListData((oldRenderListData) => {
            const newRenderListData = [...oldRenderListData];
            for (let i = 0; i < newRenderListData.length; i++) {
                newRenderListData[i].isDelete = false;
            }
            return newRenderListData;
        });
        setIsEdit(false)
    };

    const handleDelete = async () => {
        if (auth && auth?.isAuthenticated) {
            const itemsToDelete = renderListData.filter(item => item.isDelete);
            const tasksToDelete = itemsToDelete.map(item => item.taskid).join(',');

            const result = await DeleteSelectedTask({ data: { uid: auth?.user?.profile?.sub, taskids: tasksToDelete },  token: auth?.user?.access_token });
            if (result.status.toLowerCase() === "success") {
                setRenderListData((oldRenderListData) => {
                    const newRenderListData = oldRenderListData.filter(item => !item.isDelete)
                    return newRenderListData
                })
            }
            cancelDelete()
        }
    };

    const handleDownload = async ( task ) => {
        if (auth && auth?.isAuthenticated) {
            const zip = new JSZip();

            for (const url of task.url) {
                const response = await fetch(url);
                const blob = await response.blob();
                zip.file(url.split('/').pop(), blob); 
            }

            const currentDate = new Date().toISOString().slice(0, 10)
            const finalFileName = `Goodapp-${task.category}-${currentDate}.zip`; 

            zip.generateAsync({ type: 'blob' }).then((content) => {
                saveAs(content, finalFileName);
            }); 
        }
    };
    
    const hasDeletedItems = renderListData.some(item => item.isDelete);

    const getRenderListData = async () => {
        try {
            if (auth?.isAuthenticated) {
                const getScenesList = await GetImagesHistory({ data: { uid: auth?.user?.profile?.sub },  token: auth?.user?.access_token });
                console.log('getScenesList', getScenesList);
                if (getScenesList?.data?.code ===  1000 && getScenesList?.data?.data) {
                    const newList = JSON.parse(JSON.stringify(getScenesList.data.data));
                    const userLoadingNum = newList.filter(item => item.image_status === 1 || item.image_status === 2).length;
                    dispatch({
                        type: 'set_user_loading_num',
                        data: userLoadingNum
                    });
                    setRenderListData(newList);
                } else {
                    setRenderListData([]);
                }
            }
        } catch (error) {
            console.log('error', error);
        }
    }
    useEffect(() => {
        if (auth && auth?.isAuthenticated) {
            getRenderListData();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth?.isAuthenticated]);

    useEffect(() => {
        let inter = 0;
        if (tasks[3].userLoadingNum > 0) {
            inter = setInterval(() => {
                getRenderListData();
            }, 5000);
        } else {
            clearInterval(inter)
        }
        return () => {
            clearInterval(inter)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth?.user?.access_token, auth?.user?.profile?.sub, tasks[3].userLoadingNum]);

    const stylerSyncRegenAPI = async (taskid) => {
        try {
            if (auth?.user?.access_token && auth?.user?.profile?.sub) {

                const setStylerSyncRegenAPI = await StylerSyncRegenAPI({
                    data: {
                        taskid: taskid,
                        uid: auth?.user?.profile?.sub
                    },
                    token: auth?.user?.access_token
                })
                if (!setStylerSyncRegenAPI.error) {
                    getRenderListData();
                }
                console.log('setStylerSyncRegenAPI', setStylerSyncRegenAPI);
            }
        } catch (error) {
            
        }
    }

    return <div className="artspace">
        {
            renderListData.length > 0 ?
            <div className="title">
                <Menu
                    onClick={onClick}
                    selectedKeys={[current]}
                    mode="horizontal"
                    items={items}
                    className='menu'
                />
                <div className='title-but'>
                    <Input
                        size="large"
                        placeholder="large size"
                        prefix={<SearchOutlined />}
                        className='input'
                    />
                    {
                        isEdit ?
                            <div className='delete-box'>
                                <Button type="link" disabled={!hasDeletedItems} style={{ opacity: hasDeletedItems ? 1 : 0.5 }} onClick={ handleDelete }>Delete</Button>
                                <Button type="link" onClick={allDelete}>All</Button>
                                <Button type="link" onClick={cancelDelete}>Cancel</Button>
                            </div> :
                            <Button onClick={editClick} className='but'>Edit</Button>
                    }
                </div>
            </div> : <></>
        }
        <div className='content'>
            {
                renderListData.length > 0 ? renderListData.map((item, index) => <div className="content-item" key={item.taskid}>
                    {
                        item.image_status === 3 ?
                            <>
                                 <div className="container">
                                    <Image
                                        alt={item.category}
                                        src={item.url[0]}
                                        className="main-image"
                                        placeholder={<div style={{ backgroundColor: 'white', height: '100%' }}><Skeleton.Image active={true} className="main-image"/></div>}
                                    />
                                    <div className="thumbnail-container">
                                        <Image
                                            alt={item.category}
                                            src={item.url[1]}
                                            className="thumbnail"
                                            placeholder={<div style={{ backgroundColor: 'white', height: '100%' }}><Skeleton.Image active={true} className="thumbnail"/></div>}
                                        />
                                        <Image
                                            alt={item.category}
                                            src={item.url[2]}
                                            className="thumbnail"
                                            placeholder={<div style={{ backgroundColor: 'white', height: '100%' }}><Skeleton.Image active={true} className="thumbnail"/></div>}
                                        />
                                        <Image
                                            alt={item.category}
                                            src={item.url[3]}
                                            className="thumbnail"
                                            placeholder={<div style={{ backgroundColor: 'white', height: '100%' }}><Skeleton.Image active={true} className="thumbnail"/></div>}
                                        />
                                    </div>
                                </div> 
                                <div className="content-item-box">
                                    <div>{item.category}</div>
                                    <Button icon={<DownloadOutlined />} className='but' type="text" onClick={ () => handleDownload(item)}></Button>
                                </div>
                            </>
                       : <></>
                    }
                    {
                        item.image_status === 4 ?
                        <div className="error">
                            <div className='error-message'>
                                <img src={img_load_error} alt="" />        
                                <div>{"Image generation failure"}</div>
                            </div>
                            <div className='content-item-box error-action'>
                                <div>{item.category}</div>
                                <Button type="primary" className='but' onClick={() => stylerSyncRegenAPI(item.taskid)}>Generate</Button>
                            </div>
                        </div> : <></>
                    }
                    {
                        item.image_status === 1 || item.image_status === 2 ?
                        <div className="loading">
                                <div className='loading-message'>
                                    <div className='loading-message-box'>
                                        <img className='loading-img1' src={loading_img1} alt="" />
                                        <img className='loading-img2' src={loading_img2} alt="" />
                                    </div>
                                <div>{"Image generating"}</div>
                            </div>
                            <div className='content-item-box loading-action'>
                                    <div>{item.category}</div>
                                    <Button icon={<DownloadOutlined />} type="text" disabled></Button>
                            </div>
                        </div> : <></>
                    }
                    {
                        isEdit ?
                            <div className='edit-mask'>
                                {
                                    item.isDelete ?
                                    <img
                                        src={check}
                                        alt=""
                                        className='check'
                                        onClick={() => cancelOnClick(index)}
                                    /> :
                                    <div
                                        className='check'
                                        onClick={() => cancelDeleteOnClick(index)}
                                    ></div>
                                }
                            </div> : <></>
                    }
                </div>) : <div className='none-data'>
                    <img src={none_data_one} alt="" />
                    <div>{'No picture available at the moment'}</div>
                </div>
            }
        </div>
        {/* {
            renderListData.length > 0 ?
            <Pagination
                defaultCurrent={1}
                total={50}
                className='sorter'
            /> : <></>
        } */}
    </div>
};

export default MyArtSpace;