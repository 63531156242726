import React, { useState } from "react";
import {
    AppstoreOutlined,
    MailOutlined,
    SettingOutlined,
    UserOutlined,
    MenuOutlined
} from '@ant-design/icons';
import { Button, Divider } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './index.css';
import logo from '../../images/logo.png';

const MyFooter = () => {
    const location = useLocation();
    const navigate = useNavigate();
    if (location.pathname === '/toc' || location.pathname === '/privacy') {
        return null;
    };
    const contactUsClick = () => {
        navigate ('/contactus')
    };

    return <div className="footer-heart">
        <div className="left">
            <div className="logo">
                <img src={logo} alt="" />
                <div>GOOD.</div>
            </div>
            <div>
                <div>GoodAPP © 2024  All rights reserved.</div>
            </div>
        </div>
            
        <Button type="link" onClick={contactUsClick}>Contact Us</Button>
    </div>
}

export default MyFooter;