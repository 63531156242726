import React, { useRef, useState, useEffect } from 'react';
import './index.css';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';

const ContentFour = () => {
    const elementOneRef = useRef(null);
    const elementTwoRef = useRef(null);
    const elementThreeRef = useRef(null);
    const [isOneVisible, setIsOneVisible] = useState(false);
    const [isTwoVisible, setIsTwoVisible] = useState(false);
    const [isThreeVisible, setIsThreeVisible] = useState(false);
    const navigate = useNavigate();
  
    useEffect(() => {
        const observerOne = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsOneVisible(true);
                    observerOne.disconnect(); // 一旦可见，停止观察
                }
            },
            { threshold: .5 } // 视口的 50% 进入时触发
        );
        const observerTwo = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsTwoVisible(true);
                    observerTwo.disconnect(); // 一旦可见，停止观察
                }
            },
            { threshold: .5 } // 视口的 50% 进入时触发
        );
        const observerThree = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsThreeVisible(true);
                    observerThree.disconnect(); // 一旦可见，停止观察
                }
            },
            { threshold: .5 } // 视口的 50% 进入时触发
        );
    
        elementOneRef.current && observerOne.observe(elementOneRef.current);
        elementTwoRef.current && observerTwo.observe(elementTwoRef.current);
        elementThreeRef.current && observerThree.observe(elementThreeRef.current);
  
        return () => {
            elementOneRef.current && observerOne.unobserve(elementOneRef.current); // 清理观察者
            elementTwoRef.current && observerTwo.unobserve(elementTwoRef.current); // 清理观察者
            elementThreeRef.current && observerThree.unobserve(elementThreeRef.current); // 清理观察者
        };
    }, []);

    const onGenerateClick = (key) => {
        navigate(`/app/${key}`)
    }

    return (
        <div className='content-four'>
            <div className='content-four-one'>
                <div className='content-row' ref={elementOneRef}>
                    <div className={`content-row-img ${isOneVisible ? 'visible' : ''}`}>
                        <img src="https://imgv3.fotor.com/images/side/crop-image-of-the-coconut-tree-with-blue-sky-in-perfect-size-retouch-portrait-face-with-Fotor-photo-editor.png" alt="" />
                    </div>
                    <div className={`content-row-text ${isOneVisible ? 'visible' : ''}`}>
                        <h2>{"StyleID"}</h2>
                        <div>
                            <p>{"Generate Personalized Portraits with One Click, Choose from a Vast Array of Templates"}</p>
                            <p>{"Easily create personalized portraits using our diverse range of templates or by uploading your favorite reference image."}</p>
                            <p>
                                <strong>{"Vast Templates "}</strong>
                                {": Continuously updated templates in various styles; there's always one that suits you."}
                            </p>
                            <p>
                                <strong>{"Custom References "}</strong>
                                {": Support uploading personalized reference images to meet your unique needs."}
                            </p>
                            <p>
                                <strong>{"Instant Generation "}</strong>
                                {": Simple operation; generate high-quality portraits with one click."}
                            </p>
                        </div>
                        <Button
                            type="primary"
                            shape="round"
                            className='but'
                            onClick={() => onGenerateClick('StyleID')}
                        >{'Generate'}</Button>
                    </div>
                </div>
            </div>
            <div className='content-four-two'>
                <div className='content-row' ref={elementTwoRef}>
                    <div className={`content-row-text ${isTwoVisible ? 'visible' : ''}`}>
                        <h2>{"FaceID"}</h2>
                        <div>
                            <p>{"Create Fantasy Portraits with Creative Prompts Using AI"}</p>
                            <p>{"Unleash your imagination; our AI brings your dreams to life."}</p>
                            <p>
                                <strong>{"Unlimited Creativity "}</strong>
                                {": Enter any prompt, and our AI will generate matching images for you."}
                            </p>
                            <p>
                                <strong>{"Variety of Styles "}</strong>
                                {": Supports multiple art styles to meet different aesthetic preferences."}
                            </p>
                            <p>
                                <strong>{"Easy to Use "}</strong>
                                {": Convenient operation with no professional skills required; everyone can be an artist."}
                            </p>
                        </div>
                        <Button
                            type="primary"
                            shape="round"
                            className='but'
                            onClick={() => onGenerateClick('FaceID')}
                            disabled
                        >{'Coming soon'}</Button>
                    </div>
                    <div className={`content-row-img ${isTwoVisible ? 'visible' : ''}`}>
                        <img src="https://imgv3.fotor.com/images/side/crop-image-of-the-coconut-tree-with-blue-sky-in-perfect-size-retouch-portrait-face-with-Fotor-photo-editor.png" alt=""/>
                    </div>
                </div>
            </div>
            <div className='content-four-three'>
                <div className='content-row' ref={elementThreeRef}>
                    <div className={`content-row-img ${isThreeVisible ? 'visible' : ''}`}>
                        <img src="https://imgv3.fotor.com/images/side/crop-image-of-the-coconut-tree-with-blue-sky-in-perfect-size-retouch-portrait-face-with-Fotor-photo-editor.png" alt=""/>
                    </div>
                    <div className={`content-row-text ${isThreeVisible ? 'visible' : ''}`}>
                        <h2>{"Due Portrait"}</h2>
                        <div>
                            <p>{"Fun Duo Photos, Capture Beautiful Moments with Your Loved Ones"}</p>
                            <p>{"Share joy with your friends and family; create unique memories together."}</p>
                            <p>
                                <strong>{"Intelligent Matching "}</strong>
                                {": AI automatically adjusts positioning and poses for a more natural and harmonious group photo."}
                            </p>
                            <p>
                                <strong>{"Rich Scenes "}</strong>
                                {": Offers various duo photo templates and backgrounds to suit different occasions."}
                            </p>
                            <p>
                                <strong>{"Easy Sharing "}</strong>
                                {": One-click sharing to social media platforms to spread your joy to others."}
                            </p>
                        </div>
                        <Button
                            type="primary"
                            shape="round"
                            className='but'
                            onClick={() => onGenerateClick('DualPortrait')}
                            disabled
                        >{'Coming soon'}</Button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContentFour;