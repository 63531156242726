import React from "react";
import {
    Button,
    Skeleton,
    Image,
} from 'antd';
import { useNavigate } from "react-router-dom";
import './index.css';
import hot from '../../../images/hot.png';
import integral_icon from '../../../images/integral_icon.png';

const FaceIDList = (props) => {
    const { sceneList, module } = props;
    const navigate = useNavigate();

    const generateClick = (item) => {
        // BurialPointReport();
        // window.gtag('event', 'button_click', {
        //     'event_category': category,
        // });
        // window.gtag('event', 'button_click', {
        //     'event_category': category,
        //     'Click_scene': 'Click_scene'
        // });
        window.gtag('event', 'button_click', {
            'event_category': item.category,
            'Click_scene': 'event_category'
        });
        navigate('/styleid', { state: item });
        // window.gtag('event', 'button_click', {
        //     'event_category': '按钮',
        //     'event_label': '点击'
        // });
    }

    return <div className="app-faceid-box" key={module}>
        {
            sceneList.length > 0 ? sceneList.map((item, index) => <div className="app-faceid-box-item" key={item.category}>
                <div className="app-item">
                    <Image
                        alt={item.category}
                        src={item.scenes[0].imageurl}
                        className="app-item-img"
                        preview={false}
                        placeholder={<div
                            style={{
                                backgroundColor: 'white',
                                borderRadius: '16px',
                                width: '100%',
                                height: '100%'
                            }}
                        >
                            <Skeleton.Image active={true} className="app-item-img" />
                        </div>}
                        onClick={() => generateClick(item)}
                    />
                    <Image
                        alt={item.category}
                        src={item.scenes[1] ? item.scenes[1].imageurl : item.scenes[0].imageurl }
                        className="app-item-img-two"
                        preview={false}
                        placeholder={<div
                            style={{
                                backgroundColor: 'white',
                                borderRadius: '16px',
                                width: '100%',
                                height: '100%'
                            }}
                        >
                            <Skeleton.Image active={true} className="app-item-img" />
                        </div>}
                        onClick={() => generateClick(item)}
                    />
                </div>
                <div className="app-item-box">
                    <div className="app-item-styler">
                        <div>{item.category}</div>
                        {
                            item.hot ? <img src={hot} alt="" /> : <></>
                        }
                    </div>
                    <div className="app-item-action">
                        <div className="app-item-integral">
                            <img src={integral_icon} alt="" />
                            <div>{item.point}</div>
                        </div>
                        <Button type="primary" shape="round" className='but' onClick={() => generateClick(item)}>
                            {'Generate'}
                        </Button>
                    </div>
                </div>
            </div>) : <></>
        }
    </div>
}

export default FaceIDList;