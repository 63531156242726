import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';

const SigninCallback = () => {
  const navigate = useNavigate();
  const auth = useAuth();

  useEffect(() => {
    const handleCallback = async () => {
      if (auth.isLoading) return;

      try {
        if (!auth.isAuthenticated) {
          await auth.signinCallback();
        }
        
        // 获取之前存储的 URL
        const redirectUrl = sessionStorage.getItem('redirectUrl') || '/';
        console.log('Redirecting to:', redirectUrl);

        // 清除存储的 URL
        sessionStorage.removeItem('redirectUrl');

        // 重定向到之前的页面或默认页面
        navigate(redirectUrl, { replace: true });
      } catch (error) {
        console.error('Error during signin callback:', error);
        navigate('/error', { replace: true });
      }
    };

    handleCallback();
  }, [auth, navigate]);

  // return <div>Processing authentication...</div>;
  return <div></div>;
};

export default SigninCallback;