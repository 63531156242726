import React from "react";
import {
    Button,
    Skeleton,
    Image,
    Badge
} from 'antd';
import {
    RightOutlined
} from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import './index.css';
import hot from '../../../images/hot.png';
import integral_icon from '../../../images/integral_icon.png';
import homeicon from '../../../images/homeicon.png';

const StyleIDList = (props) => {
    const { sceneList, module } = props;
    const navigate = useNavigate();

    const generateClick = (item) => {
        // BurialPointReport();
        // window.gtag('event', 'button_click', {
        //     'event_category': category,
        // });
        // window.gtag('event', 'button_click', {
        //     'event_category': category,
        //     'Click_scene': 'Click_scene'
        // });
        window.gtag('event', 'button_click', {
            'event_category': item.category,
            'Click_scene': 'event_category'
        });
        navigate('/styleid', { state: item });
        // window.gtag('event', 'button_click', {
        //     'event_category': '按钮',
        //     'event_label': '点击'
        // });
    }

    return <div className="app-styleid-box" key={module}>
        <div className="app-styleid-header">
            <img src={homeicon} alt="" onClick={() => navigate(`/`)} />
            <div><RightOutlined/></div>
            <div onClick={() => navigate(`/app/StyleID`)}>{"App"}</div>
            <div><RightOutlined /></div>
            <div>{"StyleID"}</div>
        </div>
        <div className='content'>
            <div className='content-row'>
                <div className={`content-row-img`}>
                    <img src="https://imgv3.fotor.com/images/side/crop-image-of-the-coconut-tree-with-blue-sky-in-perfect-size-retouch-portrait-face-with-Fotor-photo-editor.png" alt="" />
                </div>
                <div className={`content-row-text`}>
                    <h2>{"StyleID"}</h2>
                    <div>
                        <p><strong>{"Generate Personalized Portraits with One Click, Choose from a Vast Array of Templates"}</strong></p>
                        <p>{"Choose your preferred style from our meticulously designed templates—be it trendy fashion, romantic vintage, or cutting-edge technology—we have it all prepared for you. Alternatively, upload a reference image you love along with a clear personal photo, and our powerful AI tools will instantly generate your unique portrait."}</p>
                        <p>{"Experience it now and embark on your personalized photography journey!"}</p>
                    </div>
                </div>
            </div>
        </div>
        {
            sceneList.length > 0 ? sceneList.map((item, index) => <div className="app-styleid-box-item" key={item.category}>
                <div className="app-item">
                    <Image
                        alt={item.category}
                        src={item.scenes[0].imageurl}
                        className="app-item-img"
                        preview={false}
                        placeholder={<div
                            style={{
                                backgroundColor: 'white',
                                borderRadius: '16px',
                                width: '100%',
                                height: '100%'
                            }}
                        >
                            <Skeleton.Image active={true} className="app-item-img" />
                        </div>}
                        onClick={() => generateClick(item)}
                    />
                    <Image
                        alt={item.category}
                        src={item.scenes[1] ? item.scenes[1].imageurl : item.scenes[0].imageurl }
                        className="app-item-img-two"
                        preview={false}
                        placeholder={<div
                            style={{
                                backgroundColor: 'white',
                                borderRadius: '16px',
                                width: '100%',
                                height: '100%'
                            }}
                        >
                            <Skeleton.Image active={true} className="app-item-img" />
                        </div>}
                        onClick={() => generateClick(item)}
                    />
                </div>
                <div className="app-item-box">
                    <div className="app-item-styler">
                        <div>{item.category}</div>
                        {
                            item.hot ? <img src={hot} alt="" /> : <></>
                        }
                    </div>
                    <div className="app-item-action">
                        <div className="app-item-integral">
                            <img src={integral_icon} alt="" />
                            <div>{item.point}</div>
                        </div>
                        <Button type="primary" shape="round" className='but' onClick={() => generateClick(item)}>
                            {'Generate'}
                        </Button>
                    </div>
                </div>
            </div>) : <></>
        }
    </div>
}

export default StyleIDList;