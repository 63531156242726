import React, { useState, useEffect } from 'react';
import {
    SearchOutlined
} from '@ant-design/icons';
import {
    Table,
    Space,
    ConfigProvider,
    Button
} from 'antd';
import './index.css';
import none_data_two from '../../../images/none_data_two.png';
import { GetPointsHistory } from '../../../api/request.js';
import { useAuth } from 'react-oidc-context';

const columns = [
    {
        title: 'Date',
        dataIndex: 'CreatedAt',
        key: 'date',
        render: (text) => {
            const date = new Date(text);
            return date.toUTCString();
        },
    },
    {
        title: 'Operation',
        dataIndex: 'EventType',
        key: 'operation',
    },
    {
        title: 'Points',
        dataIndex: 'Amount',
        key: 'points',
    },
];

const RechargeRecord = () => {
    const auth = useAuth();
    const [pointsListData, setPointsListData] = useState([]);

    useEffect(() => {
        (async() => {
            if (auth && auth?.isAuthenticated) {
                const data = { from: '2024-01-01T00:00:00Z', to: new Date().toISOString() }
                const jwtPayload = auth?.user.access_token.split('.')[1]
                const pointsHistory= await GetPointsHistory({ data: data, token: auth?.user?.access_token, jwtpayload: jwtPayload });

                const pointsList = JSON.parse(pointsHistory.history)
                setPointsListData(pointsList)
            }
        })()
    }, [auth?.user?.access_token, auth?.user?.profile?.sub]);

    const emptyContent = (
        <div className='none-data'>
            <img
                src={none_data_two}
                alt="No Data"
            />
            <div>{'No recharge records available at the moment'}</div>
        </div>
    );

    return <div className='recharge-record'>
        <ConfigProvider
            theme={{
                components: {
                    Table: {
                        headerBg: '#F0F0F0',
                        headerColor: 'rgba(0, 0, 0, 1)',
                        colorText: 'rgba(113, 128, 150, 1)',
                    },
                },
            }}
        >
            <Table
                columns={columns}
                dataSource={pointsListData}
                locale={{ emptyText: emptyContent }}
                rowKey="ID"
            />
        </ConfigProvider>
    </div>
};

export default RechargeRecord;