import React, { useRef, useState, useEffect } from "react";
import './index.css';
import {
    Button,
    Form,
    message,
    Upload,
    Spin,
    Image,
    Tooltip,
    Select,
    Radio,
    Modal
} from 'antd';
import {
    RightOutlined
} from '@ant-design/icons';
import Draggable from 'react-draggable';
import { Resizable } from 'react-resizable';
import {
    QuestionCircleOutlined,
    DeleteOutlined,
    InboxOutlined,
    PlusOutlined,
    RollbackOutlined,
    DownloadOutlined,
    UpOutlined,
    DownOutlined,
    EyeOutlined,
    QrcodeOutlined
} from '@ant-design/icons';
import {
    StyleIdAPI,
    StyleIdGetStyleNode
} from '../../api/request.js';
import PubInput from '../../components/pubInput/index.jsx';
import { useNavigate } from 'react-router-dom';
import 'react-resizable/css/styles.css';
import homeicon from '../../images/homeicon.png';
import noimage from '../../images/noimage.png';
import faceid9 from '../../images/faceid9.png';
import QRCodeModal from './QRcode';
import TextArea from '../../components/textAreaInput';
import { FACEIDSTYLES } from '../../constants/index.js';

const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
}

const convertURLtoBlob = (url) => {
    return fetch(url)
        .then((response) => response.blob());
};

const StyleIdToImage = (props) => {
    const { config } = props;
    const previewImageRef = useRef(null);
    const anchorRef = useRef(null);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [fileList, setFileList] = useState([]);
    const [portraitLoading, setPortraitLoading] = useState(false);

    const [thumbUrl, setThumbUrl] = useState('');
    const [disabled, setDisabled] = useState(true);
    const draggleRef = useRef(null);
    const [width, setWidth] = useState(400);
    const [height, setHeight] = useState(400);
    const [bounds, setBounds] = useState({
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
    });

    const [form] = Form.useForm();
    const [getImageUrl, setGetImageUrl] = useState('');
    const [disabledSubmit, setDisabledSubmit] = useState(false);
    // const [seed, setSeed] = useState('Please input seed');
    const [QROpen, setQROpen] = useState(false);

    // const [options, setOptions] = useState([]);

    // const navigate = useNavigate();

    const handleCancel = () => setPreviewOpen(false);
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };

    const handleChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    }
    const uploadButton = (
        <button type="button">
            <InboxOutlined />
            <div>Upload</div>
        </button>
    );
    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };
    const beforeUpload =async (file) => {
        try {
            const isJpgOrPng = file.type.startsWith('image');
            if (!isJpgOrPng) {
                message.error('You can only upload image files!');
                return Upload.LIST_IGNORE;
            }
            const isLt2M = file.size / 1024 / 1024 < 6;
            if (!isLt2M) {
                message.error('The image must be less than 6MB!');
                return Upload.LIST_IGNORE;
            }

            return isLt2M && isJpgOrPng;
        } catch (error) {
            message.error('Error, you can refresh and try again later!');
            return Upload.LIST_IGNORE;
        }
    };
    const onRemoveClick = () => {
        setPreviewImage('');
        setPreviewOpen(false);
        setPreviewTitle('');
        setFileList([]);
        setGetImageUrl('');
        setThumbUrl('');
    }
    const onPreviewImage = () => {
        handlePreview(fileList[0]);
    }
    useEffect(() => {
        (async () => {
            if (fileList[0]) {
                let thumbUrl;
                if (fileList[0].originFileObj) {
                    thumbUrl = await getBase64(fileList[0].originFileObj);
                } else {
                    thumbUrl = fileList[0].thumbUrl;
                }
                setThumbUrl(thumbUrl);
            }
        })()
    }, [fileList]);

    const handleSubmit = () => {
        if (anchorRef) {
            // anchorRef.current.tabIndex = 0;
            // anchorRef.current.focus();
        }
        if (window.innerWidth < 768) {
            window.scrollTo(0, 300);
        }
        form.submit();
    };

    const handleFileChange = async (e) => {
        setPortraitLoading(true);
        const url = e.target.src;
        const blob = await convertURLtoBlob(url);
        const file = new File([blob], 'image.png', { type: 'image/png' });

        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const newFile = {
                uid: new Date(),
                name: 'image.png',
                status: 'done',
                size: file.size,
                type: file.type,
                thumbUrl: reader.result,
                originFileObj: file
            };
            setFileList([newFile]);
            form.setFieldsValue({
                source: [newFile]
            });
            setPortraitLoading(false);
        };
    };

    const handleFormSubmit = async (values) => {

        setDisabledSubmit(true);
        try {
            setGetImageUrl('');
            const formData = new FormData();
            for (let i = 0; i < values.source.length; i++) {
                formData.append('sourceImage', values.source[i].originFileObj);
            }
            formData.append('img_width', 1024);
            formData.append('img_height',  1024);
            formData.append('positivePrompt', 'In a sleek, high-tech artificial intelligence research center, a Chinese male algorithm specialist develops advanced machine learning models. He wears a casual yet futuristic formal outfit, with a digital assistant wristband. The room is filled with servers, neural network diagrams, and holographic displays showing complex algorithms and data visualizations. Surrounding him are virtual assistants and AI prototypes, all responding to his commands. He interacts with a massive holographic screen, fine-tuning AI parameters and training data sets. The environment is bathed in cool ambient light, emphasizing the cutting-edge nature of his work. CG, 4K, masterpiece, best quality, crossed arms, standing posture(2.5D:1.5),(impasto:1.2),4K,CG,');
            formData.append('num', 1);
            // formData.append('seed', values.seed);
            const res = await StyleIdAPI({formData: formData, ip: config.ip, token: config.token});
            if (res.data.url && res.code === 1000) {
                setGetImageUrl(res.data.url);
            } else {
                message.error('Failed to generate, please try again!');
            };
        } catch (error) {
            message.error('Failed to generate, please try again!');
        };
        setDisabledSubmit(false);
    };
    const handleDownload = () => {
        const imageUrl = getImageUrl[0];

        const link = document.createElement('a');
        link.href = imageUrl;
        link.download = imageUrl.split('/')[imageUrl.split('/').length - 1];
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    const handleQR = () => {
        setQROpen(true)
    };

    // const onSelectChange = (value, option) => {
    //     form.setFieldsValue({
    //         style: option.label,
    //         prompt: option.value,
    //     })
    // }

    // const getStyleNode = async () => {
    //     try {
    //         const response = await StyleIdGetStyleNode(config);
    //         let opData = [];
    //         if (response.code === 1000) {
    //             for (let i = 0; i < response.data.length; i++) {
    //                 opData.push({
    //                     label: response.data[i],
    //                     value: response.data[i]
    //                 });
    //             }
    //             setOptions(opData);
    //         }
    //     } catch (error) { };
    // };

    const onStart = (_event, uiData) => {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();
        if (!targetRect) {
            return;
        }
        setBounds({
            left: -targetRect.left + uiData.x - targetRect.width/2,
            right: clientWidth - (targetRect.right - uiData.x) + targetRect.width/2,
            top: -targetRect.top + uiData.y - targetRect.height/2,
            bottom: clientHeight - (targetRect.bottom - uiData.y) + targetRect.height/2,
        });
    };
    const handleResize = (_event, { size }) => {
        const { width, height } = size;
        if (width >= 400) {
            setWidth(width);
        } else {
            setWidth(400);
        }
        if (height >= 400) {
            setHeight(height);
        } else {
            setHeight(400);
        }
    };
    useEffect(() => {
        if (previewOpen) {
            setWidth(400);
            setHeight(400);
        }
    }, [previewOpen]);

    useEffect(() => {
        // getStyleNode();
        window.scrollTo(0, 0);
    }, [config]);

    return (<div className="styleid-to-image">
        <div className="styleid-to-image-header">
            <img src={homeicon} alt="" />
            <div><RightOutlined/></div>
            <div>{"Playground"}</div>
            <div><RightOutlined/></div>
            <div>{"FaceID"}</div>
        </div>
        <div className="styleid-to-image-box">
            <div className="prompt">
                <Form
                    form={form}
                    name="validate_other"
                    onFinish={handleFormSubmit}
                    className='form'
                    // initialValues={{
                    //     style: FACEIDSTYLES[0].label,
                    //     prompt: FACEIDSTYLES[0].value,
                    // }}
                >
                    <div className="title">
                        <span>Source Image</span>
                        <Tooltip placement="top" title={'Upload Image'} arrow={true}>
                            <QuestionCircleOutlined />
                        </Tooltip>
                    </div>
                    <div className="upload">
                        <Form.Item
                            name="source"
                            valuePropName="fileList"
                            getValueFromEvent={normFile}
                            rules={[{ required: true, message: 'Please select image' }]}
                            className="upload-item"
                        >
                            <Upload
                                action="/#"
                                listType="picture-card"
                                fileList={fileList}
                                onPreview={handlePreview}
                                onChange={handleChange}
                                name="source"
                                maxCount={1}
                                beforeUpload={beforeUpload}
                                className='upload-source'
                                disabled={disabledSubmit}
                            >
                                {fileList.length >= 1 ? null : uploadButton}
                            </Upload>
                        </Form.Item>
                        {
                            fileList.length >= 1 ?
                                <div className="upload-action">
                                    <div className="upload-action-img">
                                        <img src={thumbUrl} alt="preview" />
                                    </div>
                                    <div className="upload-action-but">
                                        <Button
                                            type="primary"
                                            size="large"
                                            onClick={onPreviewImage}
                                            disabled={disabledSubmit}
                                        >
                                            <EyeOutlined />
                                            preview
                                        </Button>
                                        <Button
                                            type="primary"
                                            size="large"
                                            onClick={onRemoveClick}
                                            disabled={disabledSubmit}
                                        >
                                            <DeleteOutlined />
                                            delete
                                        </Button>
                                    </div>
                                </div> : <></>
                        }
                        <div className="portraits">{'(The sample portraits were all generated by GoodAPP)'}</div>
                        <div className="exa-img">
                            <img src="http://w56246p711.vicp.fun/faceidout/1/input/model_man3.jpg" alt="" onClick={handleFileChange}/>
                            <img src="http://w56246p711.vicp.fun/faceidout/1/input/model_man4.jpg" alt="" onClick={handleFileChange}/>
                            <img src="http://w56246p711.vicp.fun/faceidout/1/input/model_woman3.jpg" alt="" onClick={handleFileChange}/>
                            <img src="http://w56246p711.vicp.fun/faceidout/1/input/model_woman4.png" alt="" onClick={handleFileChange}/>
                        </div>
                        {
                            portraitLoading ? <Spin className="spin-loading" size="large" /> : <></>
                        }
                    </div>
                    <Modal
                        open={previewOpen}
                        title={
                            <div
                                style={{
                                    width: '100%',
                                    position: 'relative',
                                    cursor: 'move',
                                }}
                                onMouseOver={() => {
                                    if (disabled) {
                                        setDisabled(false);
                                    }
                                }}
                                onMouseOut={() => {
                                    setDisabled(true);
                                }}
                            >
                                {previewTitle}
                                <div
                                    style={{
                                        width: previewImageRef?.current?.offsetWidth || 400,
                                        height: previewImageRef?.current?.offsetHeight || 400,
                                        position: 'absolute',
                                        top: '32px',
                                        right: 0,
                                        zIndex: 999
                                    }}
                                >
                                </div>
                            </div>
                        }
                        footer={null}
                        onCancel={handleCancel}
                        mask={false}
                        modalRender={(modal) => (
                            <Draggable
                                disabled={disabled}
                                bounds={bounds}
                                nodeRef={draggleRef}
                                onStart={(event, uiData) => onStart(event, uiData)}
                            >
                                <div ref={draggleRef}>{modal}</div>
                            </Draggable>
                        )}
                        width={width}
                        className="preview"
                    >
                        <Resizable
                            width={width}
                            height={height}
                            onResize={handleResize}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    height: '100%'
                                }}
                            >
                                <img
                                    alt="example"
                                    style={{
                                        width: '100%',
                                        display: 'block',
                                        verticalAlign: 'middle',
                                        userDrag: 'none',
                                        WebkitUserDrag: 'none',
                                        userSelect: 'none',
                                        WebkitUserSelect: 'none',
                                        MozUserSelect: 'none',
                                        MsUserSelect: 'none'
                                    }}
                                    src={previewImage}
                                    ref={previewImageRef}
                                />
                            </div>
                        </Resizable>
                    </Modal>
                    <div style={{ display: 'block' }}>
                        {/* <div className="title">
                            <span>Style</span>
                            <Tooltip placement="top" title={'The generated Image style'} arrow={true}>
                                <QuestionCircleOutlined />
                            </Tooltip>
                        </div>
                        <Form.Item
                            name="style"
                            rules={[{ required: true, message: 'Please select style' }]}
                        >
                            <Select
                                placeholder="Please select style"
                                className="style"
                                onChange={onSelectChange}
                                options={FACEIDSTYLES}
                                disabled={disabledSubmit}
                            />
                        </Form.Item> */}
                        {/* {
                            window.innerWidth > 768 ?
                                (<>
                                    <div className="title">
                                        <span>Hair style</span>
                                        <Tooltip placement="top" title={'The generated Image hair style'} arrow={true}>
                                            <QuestionCircleOutlined />
                                        </Tooltip>
                                    </div>
                                    <Form.Item
                                        name="hair_style"
                                        rules={[{ required: true, message: 'Please select hair style' }]}
                                    >
                                        <Select
                                            placeholder="Please select hair style"
                                            className="style"
                                            onChange={onSelectChange}
                                            options={FACEIDSTYLES}
                                            disabled={disabledSubmit}
                                        />
                                    </Form.Item>
                                </>) : <></>
                        } */}
                        {/* <TextArea
                            title='Prompt'
                            name='prompt'
                            rows={4}
                            placeholder="Please input Prompt!"
                            className="text-area"
                            allowClear
                            disabled={disabledSubmit}
                        /> */}
                        {/* <PubInput
                            title='Seed'
                            name='seed'
                            type='string'
                            disabled={disabledSubmit}
                            iconTitle='The seeds can be random or fixed, and the default is random without input'
                        /> */}
                        {/* <div className="title">
                            <span>Ratio</span>
                            <Tooltip placement="top" title={'The resolution of the generated image'} arrow={true}>
                                <QuestionCircleOutlined />
                            </Tooltip>
                        </div>
                        <Form.Item
                            name="ratio"
                            noStyle
                        >
                            <Radio.Group className="ratio" disabled={disabledSubmit}>
                                <Radio.Button value="1024 1024">1024 * 1024</Radio.Button>
                                <Radio.Button value="1280 768">1280 * 768</Radio.Button>
                                <Radio.Button value="768 1280">768 * 1280</Radio.Button>
                                {
                                    config?.debug ?
                                    <Radio.Button value="856 1280">856 * 1280</Radio.Button> : <></>
                                }
                            </Radio.Group>
                        </Form.Item> */}
                    </div>
                </Form>
              
                <Button
                    type="primary"
                    shape="round"
                    size="large"
                    className="but"
                    onClick={handleSubmit}
                    disabled={disabledSubmit}
                >
                    Generate
                </Button>
            </div>
            <div ref={anchorRef} id="img"></div>
            <div className="output-image">
                <div className="output-image-bfc">
                    {
                        getImageUrl ?
                            <Image src={getImageUrl} className="img" /> :
                            disabledSubmit ? <></> :
                            <img className="noimage" src={noimage} alt="" />
                    }
                    {
                        disabledSubmit ?
                        <div className="loading-box">
                            <Spin size="large" className="loading" />
                            <div className="text" >Generating, please wait...</div>
                        </div> : <></>
                    }
                </div>
                {
                    getImageUrl ?
                        <div className="qr-but">
                            <Button
                                onClick={handleDownload}
                                type="primary"
                                icon={<DownloadOutlined />}
                                className="but"
                            >
                                Download
                            </Button>
                            <Button
                                onClick={handleQR}
                                type="primary"
                                className="qr"
                            >
                                <QrcodeOutlined />
                            </Button>
                        </div>
                         : <></>
                }
                <QRCodeModal QROpen={QROpen} setQROpen={setQROpen} getImageUrl={getImageUrl} />
            </div>
        </div>
        <div className="styleid-to-image-footer" style={{ marginTop: window.innerWidth > 768 ? (getImageUrl ? "15.7rem" : "5.7rem") : (getImageUrl ? '92px' : '30px') }}>
            <div>
                <div className="footer-title">GoodAPP</div>
                <div className="footer-content">FaceID Unlock the power of FaceID with just one photo! Effortlessly create any photo scene you desire. We offer a variety of scene templates to bring your vision to life.</div>
            </div>
            <img src={faceid9} alt="" />
        </div>
    </div>
    )
};
export default StyleIdToImage;