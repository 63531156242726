import axios from 'axios';

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

// const Url = process.env.REACT_APP_GOODAPP_URL;
const userUrl = 'https://test.tensorai.tensorslab.com';
const imageUrl = 'https://test.tensorai.tensorslab.com';

export const GenerateServerAPI = ({formData, token}) => {
    return new Promise((resolve, reject) => {
        axios.post(`${imageUrl}/v1/images/stylersync`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + token
            },
        }).then(({data}) => {
            if (data.code === 1000) {
                resolve(data);
            } else {
                reject(new Error());
            };
        }).catch((error) => {
            reject(error);
        })
    })
};

export const GetPointsHistory = ({data, token, jwtpayload}) => {
    return new Promise((resolve, reject) => {
        axios.get(`${userUrl}/user/public/point/history`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
                'X-JWT-Payload': jwtpayload
            },
            params: data,
        }).then(({data}) => {
            if (data.code === 1000) {
                resolve(data.data)
            } else {
                reject(new Error());
            };
        }).catch((error) => {
            reject(error);
        })
    })
};

export const GetPoints = ({token, jwtpayload}) => {
    return new Promise((resolve, reject) => {
        axios.get(`${userUrl}/user/public/point/get`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
                'X-JWT-Payload': jwtpayload
            },
        }).then(({data}) => {
            resolve( { data, error: null });
        }).catch((error) => {
            resolve( { data: null, error });
        })
    })
};

export const DeleteSelectedTask = ({data, token}) => {
    return new Promise((resolve, reject) => {
        axios.post(`${imageUrl}/v1/images/deleteimagestask`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        }).then(({data}) => {
            if (data.code === 1000) {
                resolve(data.data)
            } else {
                reject(new Error());
            };
        }).catch((error) => {
            reject(error);
        })
    })
};

export const StylerSyncRegenAPI = ({data, token}) => {
    return new Promise((resolve, reject) => {
        axios.post(`${imageUrl}/v1/images/stylersyncregen`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        }).then(({data}) => {
            resolve( { data, error: null });
        }).catch((error) => {
            resolve( { data: null, error });
        })
    })
};

export const GetImagesHistory = ({data, token}) => {
    return new Promise((resolve, reject) => {
        axios.post(`${imageUrl}/v1/images/getimageshistory`, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        }).then(({ data }) => {
            resolve({data, error: null});
        }).catch((error) => {
            resolve({data:null, error});
        })
    })
};

export const GetScenesInfo = (type) => {
    return new Promise((resolve, reject) => {
        axios.get(`${imageUrl}/v1/images/getscenesinfo?type=${type}`, {
            headers: {
                'Content-Type': 'application/json',
            },
        }).then(({ data }) => {
            resolve( { data, error: null });
        }).catch((error) => {
            resolve( { data: null, error });
        })
    })
};

export const FaceSwipeAPI = ({formData, ip, token}) => {
    return new Promise((resolve, reject) => {
        axios.post(`http://${ip}/v1/faceswap/specifyimage`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + token
            },
        }).then(({data}) => {
            if (data.code === 1000) {
                function makeRequest() {
                    axios.post(`http://${ip}/v1/faceswap/infobytaskid`, {
                        taskid: data.data.taskid
                    }, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token
                        }
                    }).then(res => {
                        if (res.data.data.url) {
                            clearInterval(intervalId);
                            resolve(res.data);
                        }
                    }).catch(error => {
                        clearInterval(intervalId);
                        reject(error);
                    });
                }
                const intervalId = setInterval(makeRequest, 1000);
            } else {
                reject(new Error());
            };
        }).catch((error) => {
            reject(error);
        })
    })
};

export const TextStyleImageAPI = ({param, ip, token}) => {
    return new Promise((resolve, reject) => {
        axios.post(`http://${ip}/v1/images/imagegenerate`, param, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
        }).then(({data}) => {
            if (data.code === 1000) {
                function makeRequest() {
                    axios.post(`http://${ip}/v1/images/infobytaskid`, {
                        taskid: data.data.taskid
                    }, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token
                        }
                    }).then(res => {
                        if (res.data.data.url) {
                            clearInterval(intervalId);
                            resolve(res.data);
                        }
                    }).catch(error => {
                        clearInterval(intervalId);
                        reject(error);
                    });
                }
                const intervalId = setInterval(makeRequest, 1000);
            } else {
                reject(new Error());
            };
        }).catch((error) => {
            reject(error);
        })
    })
};
export const TextStyleImageGetStyleNode = (config) => {
    return new Promise((resolve, reject) => {
        axios.get(`http://${config.ip}/v1/images/getimagestyle`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + config.token
            },
        }).then((res) => {
            resolve(res.data);
        }).catch((error) => {
            reject(error);
        })
    })
};

export const SuperfractionRepairAPI = ({formData, ip, token}) => {
    return new Promise((resolve, reject) => {
        axios.post(`http://${ip}/v1/images/imageupscaler`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + token
            },
        }).then(({data}) => {
            if (data.code === 1000) {
                function makeRequest() {
                    axios.post(`http://${ip}/v1/images/infobytaskid`, {
                        taskid: data.data.taskid
                    }, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token
                        }
                    }).then(res => {
                        if (res.data.data.url) {
                            clearInterval(intervalId);
                            resolve(res.data);
                        }
                    }).catch(error => {
                        clearInterval(intervalId);
                        reject(error);
                    });
                }
                const intervalId = setInterval(makeRequest, 1000);
            } else {
                reject(data);
            };
        }).catch((error) => {
            reject(error);
        })
    })
};

export const ImageToImageAPI = ({formData, ip, token}) => {
    return new Promise((resolve, reject) => {
        axios.post(`http://${ip}/v1/images/imagetoimage`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + token
            },
        }).then(({data}) => {
            if (data.code === 1000) {
                function makeRequest() {
                    axios.post(`http://${ip}/v1/images/infobytaskid`, {
                        taskid: data.data.taskid
                    }, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token
                        }
                    }).then(res => {
                        if (res.data.data.url) {
                            clearInterval(intervalId);
                            resolve(res.data);
                        }
                    }).catch(error => {
                        clearInterval(intervalId);
                        reject(error);
                    });
                }
                const intervalId = setInterval(makeRequest, 1000);
            } else {
                reject(new Error());
            };
        }).catch((error) => {
            reject(error);
        })
    })
};
export const ImageToImageGetStyleNode = (config) => {
    return new Promise((resolve, reject) => {
        axios.get(`http://${config.ip}/v1/images/getimagestyle`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + config.token
            },
        }).then((res) => {
            resolve(res.data);
        }).catch((error) => {
            reject(error);
        })
    })
};
export const FaceIdAPI = ({formData, ip, token}) => {
    return new Promise((resolve, reject) => {
        axios.post(`http://${ip}/v1/faceid/faceid`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + token
            },
        }).then(({data}) => {
            if (data.code === 1000) {
                function makeRequest() {
                    axios.post(`http://${ip}/v1/faceid/infobytaskid`, {
                        taskid: data.data.taskid
                    }, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token
                        }
                    }).then(res => {
                        if (res.data.data.url) {
                            clearInterval(intervalId);
                            resolve(res.data);
                        }
                    }).catch(error => {
                        clearInterval(intervalId);
                        reject(error);
                    });
                }
                const intervalId = setInterval(makeRequest, 1000);
            } else {
                reject(new Error());
            };
        }).catch((error) => {
            reject(error);
        })
    })
};
export const FaceIdGetStyleNode = (config) => {
    return new Promise((resolve, reject) => {
        axios.get(`http://${config.ip}/v1/faceid/getimagestyle?type=1`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + config.token
            },
        }).then((res) => {
            resolve(res.data);
        }).catch((error) => {
            reject(error);
        })
    })
};
export const FaceIdLiteAPI = ({formData, ip, token}) => {
    return new Promise((resolve, reject) => {
        axios.post(`http://${ip}/v1/faceidlite/faceidlite`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + token
            },
        }).then(({data}) => {
            if (data.code === 1000) {
                function makeRequest() {
                    axios.post(`http://${ip}/v1/faceidlite/infobytaskid`, {
                        taskid: data.data.taskid
                    }, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token
                        }
                    }).then(res => {
                        if (res.data.data.url) {
                            clearInterval(intervalId);
                            resolve(res.data);
                        }
                    }).catch(error => {
                        clearInterval(intervalId);
                        reject(error);
                    });
                }
                const intervalId = setInterval(makeRequest, 1000);
            } else {
                reject(new Error());
            };
        }).catch((error) => {
            reject(error);
        })
    })
};
export const StyleIdAPI = ({formData, token, uid}) => {
    return new Promise((resolve, reject) => {
        axios.post(`${imageUrl}/v1/images/stylersync`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + token
            },
        }).then(({data}) => {
            if (data.code === 1000) {
                function makeRequest() {
                    axios.post(`${imageUrl}/v1/images/infobytaskid`, {
                        taskid: data.data.taskid,
                        uid: uid
                    }, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token
                        }
                    }).then(res => {
                        if (res.data.data.url) {
                            clearInterval(intervalId);
                            resolve(res.data);
                        }
                    }).catch(error => {
                        clearInterval(intervalId);
                        reject(error);
                    });
                }
                const intervalId = setInterval(makeRequest, 5000);
            } else if ( data.code === 9000 ) {
                resolve(data);
            } else {
                reject(new Error());
            };
        }).catch((error) => {
            reject(error);
        })
    })
};
export const StyleIdGetStyleNode = (config) => {
    return new Promise((resolve, reject) => {
        axios.get(`http://${config.ip}/v1/stylerid/getimagestyle?type=9`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + config.token
            },
        }).then((res) => {
            resolve(res.data);
        }).catch((error) => {
            reject(error);
        })
    })
};
export const PoseFaceAPI = ({formData, ip, token}) => {
    return new Promise((resolve, reject) => {
        axios.post(`http://${ip}/v1/faceidpose/faceidpose`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + token
            },
        }).then(({data}) => {
            if (data.code === 1000) {
                function makeRequest() {
                    axios.post(`http://${ip}/v1/faceidpose/infobytaskid`, {
                        taskid: data.data.taskid
                    }, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token
                        }
                    }).then(res => {
                        if (res.data.data.url) {
                            clearInterval(intervalId);
                            resolve(res.data);
                        }
                    }).catch(error => {
                        clearInterval(intervalId);
                        reject(error);
                    });
                }
                const intervalId = setInterval(makeRequest, 1000);
            } else {
                reject(new Error());
            };
        }).catch((error) => {
            reject(error);
        })
    })
};
export const DoublePoseFaceAPI = ({formData, ip, token}) => {
    return new Promise((resolve, reject) => {
        axios.post(`http://${ip}/v1/sceneimagegen/scenariosimage`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + token
            },
        }).then(({data}) => {
            if (data.code === 1000) {
                function makeRequest() {
                    axios.post(`http://${ip}/v1/faceidpose/infobytaskid`, {
                        taskid: data.data.taskid
                    }, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token
                        }
                    }).then(res => {
                        if (res.data.data.url) {
                            clearInterval(intervalId);
                            resolve(res.data);
                        }
                    }).catch(error => {
                        clearInterval(intervalId);
                        reject(error);
                    });
                }
                const intervalId = setInterval(makeRequest, 1000);
            } else {
                reject(new Error());
            };
        }).catch((error) => {
            reject(error);
        })
    })
};
export const RemoveBgAPI = ({formData, ip, token}) => {
    return new Promise((resolve, reject) => {
        axios.post(`http://${ip}/v1/faceswap/specifyimage`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + token
            },
        }).then(({data}) => {
            if (data.code === 1000) {
                function makeRequest() {
                    axios.post(`http://${ip}/v1/faceswap/infobytaskid`, {
                        taskid: data.data.taskid
                    }, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + token
                        }
                    }).then(res => {
                        if (res.data.data.url) {
                            clearInterval(intervalId);
                            resolve(res.data);
                        }
                    }).catch(error => {
                        clearInterval(intervalId);
                        reject(error);
                    });
                }
                const intervalId = setInterval(makeRequest, 1000);
            } else {
                reject(new Error());
            };
        }).catch((error) => {
            reject(error);
        })
    })
};