import React, { useState, useRef } from 'react';
import {
    Form,
    Input,
    Button,
    Row,
    Col
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import emailjs from '@emailjs/browser';
import './index.css';
import contact_us_bg from '../../images/contact_us_bg.png';
import contact_us_area_bg from '../../images/contact_us_area_bg.png';

const ContactUs = () => {
    const [form] = Form.useForm();
    const onFinish = (values) => {
        console.log('values', values);
        const htmlContent = `
                <label>Name</label>
                <input type="text" name="user_name" value="${values.user_name}"/>
                <label>Email</label>
                <input type="email" name="user_email" value="${values.user_email}"/>
                <label>Message</label>
                <input type="text" name="message" value="${values.message}"/>
                <input type="submit" value="Send"/>
        `;
        const container = document.createElement('form');
        container.innerHTML = htmlContent;

        emailjs
        .sendForm('service_c1jdxrf', 'template_csy2szc', container, {
            publicKey: 'OvWXdDdSwCBO5gvmh',
        })
        .then(
            () => {
                console.log('SUCCESS!');
            },
            (error) => {
                console.log('FAILED...', error.text);
            },
        );
    }

    return (
        <div className='contact-us-bg'>
            <div
                className='content-area-bg'
                style={{
                    backgroundImage: `url(${contact_us_area_bg})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center center',
                    backgroundSize: '100% 100%'
                }}
            >
                <div className='contact-us'>
                    <img src={contact_us_bg} alt="" />
                    <Form
                        form={form}
                        layout="vertical"
                        // initialValues={{}}
                        onFinish={onFinish}
                        className='form'
                    >
                        <div>
                            <Row gutter={[16, 16]}>
                                <Col span={12}>
                                    <Form.Item
                                        label={<div className='label'>{"Name"}</div>}
                                        name='user_name'
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Input className='input' placeholder="input placeholder" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label={<div className='label'>{"Email"}</div>}
                                        name='user_email'
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Input className='input' placeholder="input placeholder" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item
                                        label={<div className='label'>{"Message"}</div>}
                                        name="message"
                                        rules={[
                                            {
                                                required: true,
                                            },
                                        ]}
                                    >
                                        <Input.TextArea className='text-area' />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                        <Row justify="center">
                            <Col>
                                <Form.Item>
                                    <Button
                                        type="primary"
                                        htmlType="submit"
                                        className='but'
                                    >Submit</Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default ContactUs;