import React, { useState, useEffect, useRef } from "react";
import './index.css';
import {
    Button,
    Form,
    message,
    Upload,
    Spin,
    Image,
    Tooltip,
    Modal,
    Skeleton,
    Badge
} from 'antd';
import {
    QuestionCircleOutlined,
    DeleteOutlined,
    InboxOutlined,
    DownloadOutlined,
    QrcodeOutlined,
    EyeOutlined,
    FileImageOutlined,
    RightOutlined
} from '@ant-design/icons';
import {
    StyleIdAPI,
} from '../../api/request.js';
import 'react-resizable/css/styles.css';
import homeicon from '../../images/homeicon.png';
import noimage from '../../images/noimage.png';
import upload_icon from '../../images/upload_icon.png';
import integral_icon from '../../images/integral_icon.png';
import template1 from '../../images/template1.png';
import template2 from '../../images/template2.png';
import template3 from '../../images/template3.png';
import template4 from '../../images/template4.png';
import template5 from '../../images/template5.png';
import template6 from '../../images/template6.png';
import template7 from '../../images/template7.png';
import face1 from '../../images/face1.jpg';
import face2 from '../../images/face2.png';
import face3 from '../../images/face3.jpeg';
import { useTasks } from '../../TasksContext';
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth, hasAuthParams } from 'react-oidc-context';


const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
}

const StyleID = (props) => {
    const { config } = props;
    const auth = useAuth();
    const tasks = useTasks();
    const myRef = useRef(null);
    const location = useLocation();
    const navigate = useNavigate();
    const { category, scenes, point } = location.state || {};
    const [templateImage, setTemplateImage] = useState(1);
    const [form] = Form.useForm();
    const [getImageUrl, setGetImageUrl] = useState([]);
    const [disabledSubmit, setDisabledSubmit] = useState(false);

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [fileList, setFileList] = useState([]);
    const [allowUpload, setAllowUpload] = useState(Boolean(auth?.isAuthenticated));

    const smoothScrollTo = (element) => {
        const top = element.getBoundingClientRect().top + window.scrollY;
        window.scrollTo(0, top);
    };
    const handleSubmit = () => {
        if (auth?.isAuthenticated) {
            if (window.innerWidth < 768) {
                if (myRef.current) {
                    smoothScrollTo(myRef.current)
                }
            }
            form.submit();
        } else {
            const currentUrl = `/app/StyleID`;
            sessionStorage.setItem('redirectUrl', currentUrl);
            auth.signinRedirect();
        }
    };
    const handleFileChange = (template) => {
        setTemplateImage(template);
    };
    const handleFormSubmit = async (values) => {
        if (auth.isAuthenticated) {
            setDisabledSubmit(true);
            try {
                setGetImageUrl('');
                const formData = new FormData();
                for (let i = 0; i < values.source.length; i++) {
                    formData.append('sourceImage', values.source[i].originFileObj);
                }
                formData.append('type', templateImage);
                formData.append('category', category);
                formData.append('uid', auth.user.profile.sub);

                const res = await StyleIdAPI({formData: formData, token: auth.user.access_token, uid: auth.user.profile.sub});
                if (res.data.url && res.code === 1000) {
                    setGetImageUrl(res.data.url);
                } else if (res.code === 9000) {
                    message.error('积分不足，请充值');
                } else {
                    message.error('生成失败，可以刷新页面之后重新生成!');
                };
            } catch (error) {
                message.error('生成失败，可以刷新页面之后重新生成!');
            };
            setDisabledSubmit(false);
        } else {
            const currentUrl = `/app/StyleID`;
            sessionStorage.setItem('redirectUrl', currentUrl);
            auth.signinRedirect();
        }
    };

    const normFile = (e) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e?.fileList;
    };
    const beforeUpload = async (file) => {
        if (auth?.isAuthenticated) {
            try {
                const isJpgOrPng = file.type.startsWith('image');
                if (!isJpgOrPng) {
                    message.error('You can only upload image files!');
                    return Upload.LIST_IGNORE;
                }
                const isLt2M = file.size / 1024 / 1024 < 6;
                if (!isLt2M) {
                    message.error('The image must be less than 6MB!');
                    return Upload.LIST_IGNORE;
                }
    
                return isLt2M && isJpgOrPng;
            } catch (error) {
                message.error('Error, you can refresh and try again later!');
                return Upload.LIST_IGNORE;
            }
        } else {
            const currentUrl = `/app/StyleID`;
            sessionStorage.setItem('redirectUrl', currentUrl);
            auth.signinRedirect();
            return false;
        }
    };
    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
    };
    const openFileDialogOnClick = () => {
        if (auth?.isAuthenticated) {
            setAllowUpload(true);
        } else {
            const currentUrl = `/app/StyleID`;
            sessionStorage.setItem('redirectUrl', currentUrl);
            auth.signinRedirect();
            setAllowUpload(false);
        }
    }
    const handleChange = ({ fileList: newFileList }) => {
        if (auth?.isAuthenticated) {
            setFileList(newFileList);
        } else {
            const currentUrl = `/app/StyleID`;
            sessionStorage.setItem('redirectUrl', currentUrl);
            auth.signinRedirect();
        }
    };

    const uploadButton = (
        <button type="button" onClick={openFileDialogOnClick}>
            <img src={upload_icon} alt="" />
            <div>Upload</div>
        </button>
    );

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [config]);

    return (<div className="styleid-bg">
        <div className="styleid">
            <div className="styleid-header">
                <img src={homeicon} alt="" onClick={() => navigate(`/`)} />
                <div><RightOutlined/></div>
                <div onClick={() => navigate(`/app/StyleID`)}>{"App"}</div>
                <div><RightOutlined /></div>
                <div onClick={() => navigate(`/app/StyleID`)}>{"StyleID"}</div>
                <div><RightOutlined /></div>
                <div>{"Playground"}</div>
            </div>
            <div className="styleid-box">
                <div className="prompt">
                    <Form
                        form={form}
                        name="validate_other"
                        onFinish={handleFormSubmit}
                        className='form'
                    >
                        <div className="upload">
                            <div className="template">
                                <div className="title">
                                    <span>{"Themes"}</span>
                                </div>
                                {
                                    scenes ?
                                    <div>
                                        <img
                                            src={scenes.find(item => item.type === templateImage).imageurl}
                                            alt=""
                                            className="template-image"
                                        />
                                        <div className="exa-img">
                                            {
                                                scenes ? scenes.map((item) => 
                                                    <img
                                                        key={item.imageurl}
                                                        src={item.imageurl}
                                                        alt={item.scenename}
                                                        onClick={() => handleFileChange(item.type)}
                                                        style={{
                                                            borderColor: templateImage === item.type ? 'rgba(255, 255, 255, 1)' : 'transparent'
                                                        }}
                                                    />
                                                ) : <></>
                                            }
                                        </div>
                                    </div> : <></>
                                }
                                <div className="portraits">
                                    <img src={integral_icon} alt=""/>
                                    <div>{point}</div>
                                </div>
                            </div>
                            <div className="action">
                                <div className="title">
                                    <span>{"Upload your photo"}</span>
                                </div>
                                <Form.Item
                                    name="source"
                                    valuePropName="fileList"
                                    getValueFromEvent={normFile}
                                    rules={[{ required: true, message: 'Please select image' }]}
                                    className="upload-item"
                                >
                                    <Upload
                                        listType="picture-card"
                                        fileList={fileList}
                                        onPreview={handlePreview}
                                        onChange={handleChange}
                                        name="source"
                                        maxCount={1}
                                        beforeUpload={beforeUpload}
                                        className='upload-source'
                                        disabled={disabledSubmit}
                                        openFileDialogOnClick={allowUpload}
                                    >
                                        {fileList.length >= 1 ? null : uploadButton}
                                    </Upload>
                                </Form.Item>
                                {previewImage && (
                                    <Image
                                        wrapperStyle={{
                                            display: 'none',
                                        }}
                                        preview={{
                                            visible: previewOpen,
                                            onVisibleChange: (visible) => setPreviewOpen(visible),
                                            afterOpenChange: (visible) => !visible && setPreviewImage(''),
                                        }}
                                        src={previewImage}
                                    />
                                )}
                                <Button
                                    type="primary"
                                    shape="round"
                                    size="large"
                                    className="but"
                                    onClick={handleSubmit}
                                    disabled={disabledSubmit}
                                >
                                    {'Generate'}
                                </Button>
                            </div>
                        </div>
                    </Form>
                </div>
                <div className="output-image">
                    <div className="output-image-bfc" ref={myRef}>
                        {
                            getImageUrl.length > 0 ? <>
                                <div className="container">
                                    <Image
                                        alt={getImageUrl[0]}
                                        src={getImageUrl[0]}
                                        className="main-image"
                                        placeholder={<div style={{ backgroundColor: 'white', height: '100%' }}><Skeleton.Image active={true} className="main-image"/></div>}
                                    />
                                    <div className="thumbnail-container">
                                        <Image
                                            alt={getImageUrl[1]}
                                            src={getImageUrl[1]}
                                            className="thumbnail"
                                            placeholder={<div style={{ backgroundColor: 'white', height: '100%' }}><Skeleton.Image active={true} className="thumbnail"/></div>}
                                        />
                                        <Image
                                            alt={getImageUrl[2]}
                                            src={getImageUrl[2]}
                                            className="thumbnail"
                                            placeholder={<div style={{ backgroundColor: 'white', height: '100%' }}><Skeleton.Image active={true} className="thumbnail"/></div>}
                                        />
                                        <Image
                                            alt={getImageUrl[3]}
                                            src={getImageUrl[3]}
                                            className="thumbnail"
                                            placeholder={<div style={{ backgroundColor: 'white', height: '100%' }}><Skeleton.Image active={true} className="thumbnail"/></div>}
                                        />
                                    </div>
                                </div>
                            </>
                                :
                                disabledSubmit ? <></> :
                                <img className="noimage" src={noimage} alt="" />
                        }
                        {
                            disabledSubmit ?
                            <div className="loading-box">
                                <Spin size="large" className="loading" />
                                <div className="text" >正在生成，请稍后...</div>
                            </div> : <></>
                        }
                    </div>
                </div>
            </div>
            {/* <div className="styleid-footer" style={{ marginTop: window.innerWidth > 768 ? (getImageUrl.length > 0 ? "15.7rem" : "5.7rem") : (getImageUrl.length > 0 ? '92px' : '30px') }}>
                <div className="footer-title">GoodAPP</div>
                <div className="footer-content">{'我们专注AI模型开发和优化部署，致力于成为AIGC视觉方案中的领军者，通过创新和可靠的解决方案，与合作伙伴一起打造视觉上的无限可能。'}</div>
            </div>
            <div className="styleid-image-list">
                <img src={template4} alt="" />
                <img src={template5} alt="" />
                <img src={template6} alt="" />
                <img src={template7} alt="" />
            </div> */}
        </div>
    </div>)
};
export default StyleID;