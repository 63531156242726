// src/auth/authConfig.js
import { WebStorageStateStore } from 'oidc-client-ts';

export const oidcConfig = {
    authority: 'https://test.user.tensorslab.com/realms/test', // OIDC提供者的URL
    client_id: 'testid',
    redirect_uri: `${window.location.origin}/signinredir`,
    post_logout_redirect_uri: window.location.origin,
    response_type: 'code', // 使用授权码流程
    scope: 'openid profile email', // 请求的权限范围
    automaticSilentRenew: true, // 自动刷新令牌
    silent_redirect_uri: `${window.location.origin}/silent-renew`,
    usePkce: true,
    userStore: new WebStorageStateStore({ store: window.localStorage }),
  };