const en = {
    home: {
        title: "Enhance Your Web Experience with AI"
    },
    header: {
        home: 'Home',
        app: 'App',
        pricing: 'Pricing',
        contactus: 'Contact Us',
        mysapce: 'My Space'
    },
    selector: {
        button: 'Go',
    }
};
export default en;