import React, { useEffect, useState } from 'react';
import './index.css';
import StyleIDList from './styleIDList';
import FaceIDList from './faceIDList';
import DualPortraitList from './dualPortraitList';
import { Tabs } from 'antd';
import { Link, useLocation, useNavigate  } from "react-router-dom";
import { GetScenesInfo } from '../../api/request.js';

const AppList = () => {
    const [type, setType] = useState(1);
    const [allSceneList, setAllSceneList] = useState([]);
    const location = useLocation();
    // const [textSceneList, setTextSceneList] = useState([]);
    // const [imageSceneList, setImageSceneList] = useState([]);
    const onChange = (key) => {
        // console.log(key);
    };
    // const items = [
    //     {
    //         key: '1',
    //         label: 'All Models',
    //         children:
    //             <Flexbox
    //                 key="all-models"
    //                 sceneList={allSceneList}
    //                 setSceneList={setAllSceneList}
    //                 module="all-models"
    //             />
    //     },
    //     {
    //         key: '2',
    //         label: 'Text To Image',
    //         children:
    //             <Flexbox
    //                 key="text-to-image"
    //                 sceneList={textSceneList}
    //                 setSceneList={setTextSceneList}
    //                 module="text-to-image"
    //             />
    //     },
    //     {
    //         key: '3',
    //         label: 'Image To Image',
    //         children:
    //             <Flexbox
    //                 key="image-to-image"
    //                 sceneList={imageSceneList}
    //                 setSceneList={setImageSceneList}
    //                 module="image-to-image"
    //             />
    //     }
    // ];

    useEffect(() => {
        try {
            if (location.pathname) {
                const parts = location.pathname.split("/").slice(-1)[0];
                let type = 1;
                if (parts === 'StyleID') {
                    type = 1;
                } else if (parts === 'FaceID') {
                    type = 2;
                } else if (parts === 'DualPortrait') {
                    type = 3;
                }

                setType(type);
                (async() => {
                    const scenesInfo = await GetScenesInfo(type);
                    if (scenesInfo.error) {
                        // to be custom
                        alert("http error")
                    }
                    if (scenesInfo.data?.code === 1000) {
                        const getScenesList = scenesInfo.data.data
                        if (getScenesList.length > 0) {
                            const newList = JSON.parse(JSON.stringify(getScenesList));
                            for (let i = 0; i < newList.length; i++) {
                                newList[i].scenes = newList[i].scenes.sort((a, b) => a.order - b.order)
                            }
                            newList.sort((a, b) => a.order - b.order);
                            setAllSceneList(newList);
                            // setTextSceneList(newList);
                            // setImageSceneList(newList);
                        }
                    } else {
                        setAllSceneList([]);
                    }
                })()
            }
        } catch (error) { }
    }, [location]);

    return (
        <div className='applist'>
            {/* <Tabs
                defaultActiveKey="1"
                items={items}
                onChange={onChange}
                tabBarGutter={36}
            /> */}
            {
                type === 1 ?
                    <StyleIDList
                        key="StyleID-models"
                        sceneList={allSceneList}
                        module="StyleID-models"
                    /> :
                type === 2 ?
                    <FaceIDList
                        key="FaceID-models"
                        sceneList={allSceneList}
                        module="FaceID-models"
                    /> :
                type === 3 ?
                    <DualPortraitList
                        key="DualPortrait-models"
                        sceneList={allSceneList}
                        module="DualPortrait-models"
                    /> : <></>
            }
        </div>
    );
};

export default AppList;